import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import the pages 
import Home from './pages/home';
import Services from './pages/services';
import FAQ from './pages/faq.jsx';
import Contact from './pages/contact';
import CreationSiteWeb from './pages/creation-siteweb.jsx';
import CommunityManagement from './pages/community-management.jsx';
import CampagnePublicitaire from './pages/campagne-publicitaire.jsx';
import MentionsLegales from './pages/mentions-legales.jsx';
import Confidentialite from './pages/confidentialite.jsx';
import ConditionUtilisation from './pages/condition-utilisation.jsx';

// Import the layout
import Navbar from './components/layout/navbar';
import Footer from './components/layout/footer';
import ScrollToTop from './components/elements/scrollToTop';

function App() {
  return (
    <Router>
      <div>
        <ScrollToTop />
        <Navbar />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/CreationSiteWeb" element={<CreationSiteWeb />} />
          <Route path="/CommunityManagement" element={<CommunityManagement />} />
          <Route path="/CampagnePublicitaire" element={<CampagnePublicitaire />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="/confidentialite" element={<Confidentialite />} />
          <Route path="/condition-utilisation" element={<ConditionUtilisation />} />
        </Routes>

        <Footer />

      </div>
    </Router>
  );
}

export default App;
