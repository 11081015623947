import React, { useRef } from 'react';
import useInView from './../elements/useInView.jsx';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();
  const [setElementRef, isVisible] = useInView({ threshold: 0.1 });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_vedx07p', 'template_4ezrg1u', form.current, {
        publicKey: 'RTPmtDubUhgHphxrl',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div
      ref={setElementRef}
      className="bg-[#FAFAFA] flex items-center justify-center p-44 transition-transform duration-500"
    >
      <form
        ref={form}
        onSubmit={sendEmail}
        className={`transition-transform ease-in-out duration-700 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-32 opacity-0'}`}
      >
        <h2 className="text-4xl font-bold text-[#595959] text-center mb-10">
          NOUS CONTACTER
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 ">
          <div>
            <input
              type="text"
              name="lastName"
              placeholder="Nom"
              className="w-full p-3 text-base lg:w-80 lg:p-4 lg:text-lg bg-[#F1F1F1] text-gray-700 shadow-sm rounded-md focus:outline-none focus:ring focus:ring-[#ffb623]"
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="firstName"
              placeholder="Prénom"
              className="w-full p-3 text-base lg:w-80 lg:p-4 lg:text-lg bg-[#F1F1F1] text-gray-700 shadow-sm rounded-md focus:outline-none focus:ring focus:ring-[#ffb623]"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <input
              type="email"
              name="email"
              placeholder="Mail"
              className="w-full p-3 text-base lg:w-80 lg:p-4 lg:text-lg bg-[#F1F1F1] text-gray-700 shadow-sm rounded-md focus:outline-none focus:ring focus:ring-[#ffb623]"
              required
            />
          </div>
          <div>
            <input
              type="tel"
              name="phone"
              placeholder="Tel"
              className="w-full p-3 text-base lg:w-80 lg:p-4 lg:text-lg bg-[#F1F1F1] text-gray-700 shadow-sm rounded-md focus:outline-none focus:ring focus:ring-[#ffb623]"
              required
            />
          </div>
        </div>

        <div className="mb-4">
          <textarea
            name="message"
            placeholder="Message"
            rows="4"
            className="w-full p-3 text-base lg:w-180 lg:p-4 lg:text-lg bg-[#F1F1F1] text-gray-700 shadow-sm rounded-md focus:outline-none focus:ring focus:ring-[#ffb623]"
            required
          ></textarea>
        </div>

        <div className="flex justify-center">
          <input value="ENVOYER" type="submit" className={`shadow-sm bg-[#f4b814] text-[#FAFAFA] px-6 py-2 rounded-md hover:bg-[#FAFAFA] hover:text-[#f4b814] hover:cursor-pointer transition-all ease-in-out duration-300 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}/> {/* Modifiez le type de bouton en submit */}
        </div>
      </form>
    </div>
  );
};
export default ContactForm;