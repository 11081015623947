import Header from '../components/layout/header.jsx'
import backgroundImage from "./../assets/img/wallpaper_home.webp";
import Button from '../components/elements/button.jsx'; // Assurez-vous que le bouton est bien importé
import strategy from "./../assets/img/strategy_web.webp";
import socialMedia from "./../assets/img/gestion_insta.webp";

const CommunityService = () => {
  return (
    <div className='bg-[#FAFAFA]'>
      {/* Header avec l'image de fond */}
      <Header 
        backgroundImage={backgroundImage}
        title="Community Management"
        desc="Construisez une communauté engagée autour de votre marque grâce à notre expertise en gestion de réseaux sociaux et de contenu interactif."
        buttonON="true"
      />

      {/* Section présentation du service */}
      <section className="container mx-auto py-16 px-4">
        {/* Explication détaillée */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Développez votre communauté en ligne</h2>
          <p className="text-lg text-gray-600 leading-relaxed max-w-4xl mx-auto">
            En tant qu'experts en Community Management, nous gérons vos réseaux sociaux pour créer une relation forte et durable avec vos clients. 
            Notre objectif est de développer une communauté engagée autour de votre marque, en créant du contenu pertinent, en gérant les interactions avec vos abonnés, 
            et en veillant à ce que votre présence en ligne reflète vos valeurs et vos objectifs commerciaux. Que vous soyez sur Facebook, Instagram, Twitter, LinkedIn, ou d'autres plateformes, nous avons la stratégie adaptée à vos besoins.
          </p>
        </div>

        {/* Images illustratives avec descriptions */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <div className="relative">
            <img 
              src={strategy}
              alt="Stratégie de contenu engageante"
              className="rounded-lg shadow-lg object-cover w-full h-[31em]"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <p className="text-white text-xl font-semibold">Stratégie de contenu engageante</p>
            </div>
          </div>
          <div className="relative">
            <img 
              src={socialMedia}
              alt="Gestion des réseaux sociaux"
              className="rounded-lg shadow-lg object-cover w-full h-[31em]"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <p className="text-white text-xl font-semibold">Gestion des réseaux sociaux</p>
            </div>
          </div>
        </div>

        {/* Points clés du service */}
        <div className="text-center mb-16">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6">Pourquoi nous choisir pour gérer votre communauté ?</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-[#F1F1F1] p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-bold mb-4">Stratégie Personnalisée</h4>
              <p className="text-gray-600">Nous élaborons des stratégies de contenu et de communication sur mesure pour engager vos abonnés et atteindre vos objectifs.</p>
            </div>
            <div className="bg-[#F1F1F1] p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-bold mb-4">Engagement et Interaction</h4>
              <p className="text-gray-600">Nous créons et gérons des conversations interactives avec vos clients pour renforcer leur engagement avec votre marque.</p>
            </div>
            <div className="bg-[#F1F1F1] p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-bold mb-4">Analyse et Optimisation</h4>
              <p className="text-gray-600">Nous analysons les performances de vos publications et ajustons les stratégies en fonction des résultats pour maximiser l'impact.</p>
            </div>
          </div>
        </div>

        {/* Call to Action section */}
        <div className="text-center py-16 bg-[#F1F1F1] text-gray-600 rounded-lg shadow-sm">
          <h3 className="text-3xl text-gray-800 font-semibold mb-6">Besoin d'une gestion efficace de votre communauté ?</h3>
          <p className="text-lg mb-8 ml-8 mr-8 text-gray-500 ">
            Contactez-nous dès maintenant pour définir une stratégie de Community Management efficace et renforcer votre présence en ligne.
          </p>
          <Button text="NOUS CONTACTER" link="/contact" />
        </div>
      </section>
    </div>
  );
}

export default CommunityService;