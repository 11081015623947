import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/layout/header.jsx";
import backgroundImage from "./../assets/img/wallpaper_home.webp";

const ConditionUtilisation = () => {
    return (
        <div>
            <Header 
                backgroundImage={backgroundImage}
                title="CONDITIONS GÉNÉRALES D'UTILISATION"
            />

            <div className="mt-32 ml-80 mr-80 mb-32 bg-white p-8 min-h-screen text-justify">
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Présentation</h2>
                    <p className="mb-2">
                    Les présentes Conditions Générales d'Utilisation (ci-après « CGU ») ont pour objet de définir
                    les modalités d'accès et d'utilisation du site <strong>orazio-consulting.fr</strong> (ci-après « le Site »),
                    exploité par <strong>ORAZIO Consulting EI</strong>, immatriculée au registre du commerce sous le
                    numéro <strong>982 419 830 000 12</strong>, dont le siège social est situé <strong><Link to="https://www.google.com/maps/dir//orazio+consulting/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0xd55278b0a36c04d:0xf8b2e301b6c0c011?sa=X&ved=1t:3061&ictx=111" className="hover:text-[#f4b814] transition-all">149 Cours de la Marne, 33800 Bordeaux, France</Link></strong>.
                    </p>
                    <p>
                    En accédant et en naviguant sur ce site, vous acceptez, sans réserve, les présentes CGU. Si vous
                    n'acceptez pas ces conditions, nous vous invitons à quitter le Site.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. Définitions</h2>
                    <p className="mb-2">
                    - <strong>Site :</strong> désigne le site internet accessible à l'adresse <strong>orazio-consulting.fr</strong>.
                    </p>
                    <p className="mb-2">
                    - <strong>Utilisateur :</strong> toute personne physique ou morale accédant au Site pour utiliser
                    les services offerts.
                    </p>
                    <p>
                    - <strong>Contenu :</strong> toutes les informations, données, textes, images, vidéos, ou autres
                    éléments publiés sur le Site.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. Accès au Site</h2>
                    <p className="mb-2">
                    Le Site est accessible gratuitement à tout Utilisateur disposant d'un accès à Internet. Tous les
                    coûts liés à l'accès au Site, que ce soit les frais de matériel, logiciels ou accès à Internet
                    sont exclusivement à la charge de l'Utilisateur.
                    </p>
                    <p>
                    Le Site est disponible  <strong>24h/24 et 7j/7</strong>, sauf en cas de force majeure ou d'événements hors du contrôle
                    de la société, notamment liés à des maintenances techniques.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Responsabilité de l'Utilisateur</h2>
                    <p className="mb-2">
                    L'Utilisateur est seul responsable de l'utilisation qu'il fait du contenu du Site. Tout
                    téléchargement de Contenu se fait aux risques et périls de l'Utilisateur.
                    </p>
                    <p>
                    L'Utilisateur s'engage à utiliser le Site de manière légale et conforme aux présentes CGU. Il est
                    strictement interdit de diffuser, publier, ou télécharger sur le Site des contenus illicites,
                    diffamatoires, obscènes, violents ou portant atteinte aux droits de tiers.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Propriété intellectuelle</h2>
                    <p className="mb-2">
                    La structure générale ainsi que les logiciels, textes, images, vidéos, graphiques et tout autre
                    élément composant le Site sont la propriété exclusive de <strong>ORAZIO Consulting</strong> et sont protégés par
                    les lois en vigueur sur la propriété intellectuelle.
                    </p>
                    <p>
                    Toute reproduction, représentation ou exploitation totale ou partielle des contenus du Site,
                    sans autorisation préalable de <strong>ORAZIO Consulting</strong>, est strictement interdite et sera susceptible
                    d'entraîner des poursuites judiciaires.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">6. Limitation de responsabilité</h2>
                    <p className="mb-2">
                    La société <strong>ORAZIO Consulting</strong> ne pourra être tenue responsable des dommages directs ou indirects causés au matériel de l'Utilisateur lors de l'accès au Site, ni des conséquences liées à l'utilisation du Site ou d'une défaillance du service.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">7. Liens hypertextes</h2>
                    <p>
                    Le Site peut contenir des liens hypertextes vers d'autres sites. Cependant, <strong>ORAZIO Consulting</strong> ne
                    peut être tenu responsable du contenu ou des pratiques de ces sites externes. L'accès à ces sites se
                    fait aux risques et périls de l'Utilisateur.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">8. Modification des CGU</h2>
                    <p>
                    <strong>ORAZIO Consulting</strong> se réserve le droit de modifier les présentes CGU à tout moment. L'Utilisateur est
                    donc invité à les consulter régulièrement. Les modifications prendront effet dès leur mise en ligne.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">9. Droit applicable et juridiction</h2>
                    <p>
                    Les présentes CGU sont soumises au droit français. Tout litige relatif à l'interprétation ou à l'exécution
                    des présentes sera de la compétence exclusive des tribunaux de Paris.
                    </p>
                </section>
            </div>
        </div>
    );
}

export default ConditionUtilisation;