import React, { useState } from "react";
import useInView from './useInView.jsx'; // Assurez-vous que le chemin d'importation est correct

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [setElementRef, isVisible] = useInView({ threshold: 0.1 }); // Ajustez le seuil si nécessaire

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-600" ref={setElementRef}>
      <button
        onClick={toggleFAQ}
        className={`w-full flex justify-between items-center py-4 mr-20 text-left text-gray-900 font-semibold focus:outline-none transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
      >
        <span className="m-1">{question}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-4 w-4 max-h-4 max-w-4min-h-4 min-w-4 sm:h-6 sm:w-6 sm:max-h-6 sm:max-w-6 transition-transform duration-300 ${isOpen ? "rotate-180 text-[#f4b814]" : "rotate-0 text-black"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      <div
        className={`overflow-hidden transition-all duration-1000 ease-in-out ${isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}
        style={{ transitionProperty: 'max-height, opacity' }}
      >
        <div className="text-gray-600 py-2">
          {answer}
        </div>
      </div>
    </div>
  );
};

export default FAQItem;
