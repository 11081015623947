import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Button = ({ text, link }) => {
    const [isTextVisible, setIsTextVisible] = useState(false); // État pour le texte

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTextVisible(true); // Afficher le texte après 100 ms
        }, 100);

        return () => clearTimeout(timer); // Nettoyage
    }, []);

    return (
        <Link to={link} className={`shadow-sm bg-[#f4b814] text-[#FAFAFA] px-6 py-2 rounded-md hover:bg-[#FAFAFA] hover:text-[#f4b814] hover:cursor-pointer transition-all ease-in-out duration-300 ${isTextVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
        {text}
        </Link>
    );
};

export default Button;