import React from 'react';
import Logo from './../../assets/img/Orazio_Consulting_Logo.webp';
import { Link } from 'react-router-dom';
import instagramIcon from './../../assets/svg/instagram-icon.svg';
import youtubeIcon from './../../assets/svg/youtube-icon.svg';
import tiktokIcon from './../../assets/svg/tiktok-icon.svg';
import discordIcon from './../../assets/svg/discord-icon.svg';


const Footer = () => {
  return (
    <footer className="bg-[#F0F0F0] py-8">
      <div className="container mx-auto flex flex-col items-center justify-evenly m-4 lg:flex-row">
        {/* Logo et réseaux sociaux */}
        <div className="flex flex-col items-center justify-center">
            <img src={Logo} alt="Orazio Consulting" className="w-60" />
          <div className="flex flex-row justify-center items-center space-x-4 mt-4">
            <Link to="https://www.instagram.com/orazioconsulting/" className="bg-[#FAFAFA] p-2 rounded-full shadow-xl hover:scale-125 transition-all">
              <img src={instagramIcon} alt="Instagram" className="h-6 w-6" />
            </Link>
            <Link to="https://www.youtube.com/@memorys-dev" className="bg-[#FAFAFA] p-2 rounded-full shadow-xl hover:scale-125 transition-all">
              <img src={youtubeIcon} alt="YouTube" className="h-6 w-6" />
            </Link>
            <Link to="/" className="bg-[#FAFAFA] p-2 rounded-full shadow-xl hover:scale-125 transition-all">
              <img src={tiktokIcon} alt="Tiktok" className="h-6 w-6" />
            </Link>
            <Link to="https://discord.gg/XTrsuRhKaD" className="bg-[#FAFAFA] p-2 rounded-full shadow-xl hover:scale-125 transition-all">
              <img src={discordIcon} alt="Discord" className="h-6 w-6" />
            </Link>
          </div>
        </div>

        {/* Liens à propos, raccourcis, contact */}
        <div className="flex flex-col justify-center items-center lg::justify-start lg:items-start lg:flex-row mt-8 lg:mt-0 space-y-4 lg:space-y-0 lg:space-x-16">
          {/* À Propos */}
          <div className='flex flex-col justify-center items-center lg::justify-start lg:items-start'>
            <h2 className="font-bold text-gray-700">LÉGAL</h2>
            <ul className="flex flex-col justify-center items-center lg::justify-start lg:items-start mt-2 space-y-2">
              <li><Link to="/mentions-legales" className="text-gray-500 hover:text-[#f4b814] transition-all">Mentions Légale</Link></li>
              <li><Link to="/confidentialite" className="text-gray-500 hover:text-[#f4b814] transition-all">Confidentialité</Link></li>
              <li><Link to="/condition-utilisation" className="text-gray-500 hover:text-[#f4b814] transition-all">CGU</Link></li>
            </ul>
          </div>
          {/* Raccourcis */}
          <div className='flex flex-col justify-center items-center lg::justify-start lg:items-start'>
            <h2 className="font-bold text-gray-700">RACCOURCIS</h2>
            <ul className="flex flex-col justify-center items-center lg::justify-start lg:items-start mt-2 space-y-2">
              <li><Link to="/" className="text-gray-500 hover:text-[#f4b814] transition-all">Accueil</Link></li>
              <li><Link to="/services" className="text-gray-500 hover:text-[#f4b814] transition-all">Services</Link></li>
              <li><Link to="/faq" className="text-gray-500 hover:text-[#f4b814] transition-all">F.A.Q</Link></li>
              <li><Link to="/contact" className="text-gray-500 hover:text-[#f4b814] transition-all">Contact</Link></li>
            </ul>
          </div>
          {/* Contact */}
          <div className='flex flex-col justify-center items-center lg::justify-start lg:items-start'>
            <h2 className="font-bold text-gray-700">CONTACT</h2>
            <ul className="flex flex-col justify-center items-center lg::justify-start lg:items-start mt-2 space-y-2">
            <li><Link to="" className="text-gray-500 hover:text-[#f4b814] transition-all">ORAZIO Consulting EI</Link></li>
              <li><Link to="https://www.google.com/maps/dir//orazio+consulting/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0xd55278b0a36c04d:0xf8b2e301b6c0c011?sa=X&ved=1t:3061&ictx=111" className="text-gray-500 hover:text-[#f4b814] transition-all">149 Cours de la Marne, 33800 Bordeaux</Link></li>
              <li><Link to="javascript:void(0)" className="text-gray-500 hover:text-[#f4b814] transition-all" onClick={() => window.location = 'tel:0766189857'}>07 66 18 98 57</Link></li>
              <li><Link to='javascript:void(0)' className="text-gray-500 hover:text-[#f4b814] transition-all" onClick={() => window.location = 'mailto:contact@orazio-consulting.fr'}>contact@orazio-consulting.fr</Link></li>
            </ul>
          </div>
        </div>
      </div>
      
      {/* Line */}
      <div className="w-1/2 mx-auto border-t border-[#f4b814] my-8"></div>


      {/* Crédits */}
      <div className="mt-8 mr-8 ml-8 text-center text-gray-500">
        ©2024 ORAZIO Consulting - Tous droits réservés - Création Baptiste Orazio
      </div>
    </footer>
  );
}

export default Footer;
