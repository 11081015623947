// src/components/elements/Card.js

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const Card = ({ icon, title, desc, link}) => {
  const [isVisible, setIsVisible] = useState(false); // État pour suivre la visibilité de la carte
  const cardRef = useRef(null); // Référence à l'élément de la carte

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Définir l'état à vrai si la carte est visible
          observer.unobserve(cardRef.current); // Ne plus observer après l'animation
        }
      },
      { threshold: 0.1 } // 10 % de l'élément doit être visible avant que l'animation ne commence
    );

    if (cardRef.current) {
      observer.observe(cardRef.current); // Observer l'élément
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current); // Nettoyage de l'observateur
      }
    };
  }, []);

  return (
    <div
      ref={cardRef}
      className={`w-[300px] h-[370px] bg-[#F0F0F0] z-0 text-balance rounded-xl shadow-sm m-5 p-5 flex flex-col hover:cursor-pointer justify-around items-start transition-transform duration-700 ease-in-out transform ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-32 opacity-0'}`}
    >
      <img src={icon} alt="icon" className="h-12 w-12 mb-3" />
      <h2 className="text-[20px] font-bold text-[#595959] mb-2">{title}</h2>
      <p className="text-[#848484] text-[15px]">{desc}</p>
      <h6 className="text-[#595959] pt-5 hover:translate-x-3 transition-all"><Link to={link}>En savoir plus</Link></h6>
    </div>
  );
};

export default Card;
