import React from 'react';
import { motion } from 'framer-motion';
import useInView from './useInView.jsx'; // Assurez-vous de mettre le bon chemin pour useInView
import imageAbout1 from './../../assets/img/imageAbout1.webp';
import imageAbout2 from './../../assets/img/imageAbout2.webp';
import imageAbout3 from './../../assets/img/imageAbout3.webp';
import imageAbout4 from './../../assets/img/imageAbout4.webp';

const About = () => {
  // Références et visibilité
  const [textRef, isTextVisible] = useInView({ threshold: 0.1 }); // Détection de visibilité pour le texte
  const [imageRefs, setImageRefs] = React.useState([]); // Pour stocker les références des images
  const [imageVisible, setImageVisible] = React.useState(false); // Pour suivre la visibilité des images

  // Fonction pour définir les références des images
  const setRef = (element) => {
    if (element && !imageRefs.includes(element)) {
      setImageRefs((prev) => [...prev, element]);
    }
  };

  // Observer pour les images
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setImageVisible(true);
          observer.unobserve(entry.target); // Stop observing une fois qu'il est visible
        }
      });
    });

    imageRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      imageRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [imageRefs]);

  // Variantes d'animation
  const imageVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="bg-[#F1F1F1] p-8 min-h-screen flex items-center justify-center">
      <div className="items-center justify-center container mx-auto grid md:grid-cols-2 gap-8">
        {/* Section des images */}
        <div className="grid grid-cols-2 gap-8">
          {[...Array(4)].map((_, index) => (
            <motion.img
              key={index}
              src={[imageAbout1, imageAbout2, imageAbout3, imageAbout4][index]}
              alt={`Image ${index + 1}`}
              className="rounded-lg object-cover h-[300px] w-[300px]"
              initial="hidden"
              animate={imageVisible ? "visible" : "hidden"}
              variants={imageVariants}
              transition={{ duration: 0.5, delay: index * 0.2 }} // Délai pour chaque image
              ref={setRef} // Définition de la référence
            />
          ))}
        </div>

        {/* Section du texte */}
        <div className="text-grey-700">
          <motion.h2
            ref={textRef} // Référence pour le texte
            className="text-3xl text-[#595959] font-bold mb-4"
            initial="hidden"
            animate={isTextVisible ? "visible" : "hidden"}
            variants={textVariants}
            transition={{ duration: 0.8 }} // Durée de l'animation
          >
            À propos de notre entreprise
          </motion.h2>
          <motion.p
            ref={textRef} // Référence pour le texte
            className="text-gray-500 mb-6"
            initial="hidden"
            animate={isTextVisible ? "visible" : "hidden"}
            variants={textVariants}
            transition={{ duration: 0.8 }} // Durée de l'animation
          >
            ORAZIO Consulting est une entreprise spécialisée dans le conseil en stratégie digitale. Nous accompagnons les entreprises dans leur transformation digitale en leur proposant des solutions innovantes et adaptées à leurs besoins.
          </motion.p>
          <motion.p
            ref={textRef} // Référence pour le texte
            className="text-gray-500 mb-6"
            initial="hidden"
            animate={isTextVisible ? "visible" : "hidden"}
            variants={textVariants}
            transition={{ duration: 0.8 }} // Durée de l'animation
          >
            Notre équipe est composée de professionnels expérimentés dans le domaine du digital. Nous mettons notre expertise à votre service pour vous aider à atteindre vos objectifs et à développer votre activité sur le web.
          </motion.p>
          <motion.p
            ref={textRef} // Référence pour le texte
            className="text-gray-500 mb-6"
            initial="hidden"
            animate={isTextVisible ? "visible" : "hidden"}
            variants={textVariants}
            transition={{ duration: 0.8 }} // Durée de l'animation
          >
            Que vous soyez une petite, moyenne ou grande entreprise, nous avons la solution qu'il vous faut. Contactez-nous dès aujourd'hui pour en savoir plus sur nos services et obtenir un devis personnalisé.
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default About;
