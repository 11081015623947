import React, { useEffect, useState } from "react";
import imageCEO from "./../../assets/img/baptiste.webp";

const CEO = () => { 
    const [isTextVisible, setIsTextVisible] = useState(false); // État pour le texte

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTextVisible(true); // Afficher le texte après 100 ms
        }, 100);

        return () => clearTimeout(timer); // Nettoyage
    }, []);

    return (
        <div className={`flex flex-col md:flex-row justify-center items-center pb-40 transition-all ease-in-out duration-700 ${isTextVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
            <div className="mr-10 ml-10">
                <img src={imageCEO} className="w-44 md:w-72 rounded-full "/>
            </div>
            <div className="mr-10 ml-10">
                <ul className="flex flex-col justify-center items-center md:items-start pt-5 md:pt-0">
                    <li><h3 className="font-bold pb-2">Baptiste ORAZIO - CEO</h3></li>
                    <li><h3 className="pb-1">07 66 18 98 57</h3></li>
                    <li><h3 className="pb-1">baptiste.orazio@orazio-consulting.fr</h3></li>
                    <li><h3 className="pb-1">149 Cours de la Marne, 33800 Bordeaux</h3></li>
                </ul>
            </div>
          
        </div>

        
    );
}

export default CEO;