import React from "react";
import Button from "./button.jsx";  // Import the Button component


const ServicesPages = ({ title, buttonLabel, buttonLink, imageUrl }) => {    
    return (
        <div className="relative w-full h-screen overflow-hidden shadow-lg cursor-pointer brightness-75 hover:brightness-100 transition-all ease-in-out duration-150">
                 <img 
                    src={imageUrl} 
                    alt={title} 
                    className="w-full h-full object-cover" 
                />
            <div className="absolute inset-0 flex flex-col items-center justify-end bg-gradient-to-t from-black/50 via-black/0">
                <div className="p-20 text-white flex flex-col justify-center items-center">
                    <h2 className="text-center text-2xl font-bold mb-4">{title}</h2>
                    <Button text={buttonLabel} link={buttonLink} />
                </div>
            </div>
        </div>
    );
}

export default ServicesPages;