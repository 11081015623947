import React from "react";
import { Link } from "react-router-dom";
import Header from "./../components/layout/header.jsx";
import backgroundImage from "./../assets/img/wallpaper_home.webp";

const Confidentialite = () => {
    return (
        <div>
            <Header 
                backgroundImage={backgroundImage}
                title="POLITIQUE DE CONFIDENTIALITÉ"
            />

            <div className="mt-32 ml-80 mr-80 mb-32 bg-white p-8 min-h-screen text-justify">
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                    <p>
                    La présente Politique de Confidentialité a pour objectif de vous informer sur la collecte, l'utilisation, la conservation et la protection de vos données personnelles par la société <strong>ORAZIO Consulting</strong> lorsque vous utilisez notre site <strong>orazio-consulting.fr</strong>.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. Données collectées</h2>
                    <p className="mb-2">
                    Nous pouvons collecter les données suivantes :
                    </p>
                    <ul>
                        <li className="ml-10"><strong> • </strong>Informations d'identification (nom, prénom, adresse, email, etc.)</li>
                        <li className="ml-10"><strong> • </strong>Informations de connexion (adresse IP, type de navigateur, etc.)</li>
                        <li className="ml-10"><strong> • </strong>Données relatives à votre utilisation de nos services (temps de connexion, pages visitées, etc.)</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. Utilisation des données</h2>
                    <p className="mb-2">
                    Les données que nous collectons sont utilisées pour :
                    </p>
                    <ul>
                        <li className="ml-10"><strong> • </strong>Améliorer notre service et personnaliser votre expérience.</li>
                        <li className="ml-10"><strong> • </strong>Répondre à vos demandes et vous fournir un support client.</li>
                        <li className="ml-10"><strong> • </strong>Envoyer des informations et des mises à jour concernant notre service.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Conservation des données</h2>
                    <p>
                    Nous conservons vos données personnelles aussi longtemps que nécessaire pour atteindre les objectifs décrits dans cette politique, conformément aux exigences légales et réglementaires.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Sécurité des données</h2>
                    <p>
                    Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos données personnelles contre la perte, l'utilisation abusive ou l'accès non autorisé.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">6. Vos droits</h2>
                    <p className="mb-2">
                    Conformément à la législation en vigueur, vous disposez des droits suivants concernant vos données personnelles :
                    </p>
                    <ul>
                        <li className="ml-10"><strong> • Droit d'accès :</strong> Vous avez le droit de demander une copie des données que nous détenons à votre sujet.</li>
                        <li className="ml-10"><strong> • Droit de rectification :</strong> Vous avez le droit de demander la correction de vos données personnelles si elles sont inexactes.</li>
                        <li className="ml-10"><strong> • Droit d'opposition :</strong> Vous avez le droit de vous opposer à l'utilisation de vos données à des fins de marketing.</li>
                        <li className="ml-10"><strong> • Droit à l'effacement :</strong> Vous avez le droit de demander la suppression de vos données personnelles dans certaines circonstances.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">7. Modifications de cette Politique</h2>
                    <p>
                    Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment. Les modifications seront publiées sur cette page avec la date de mise à jour.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">8. Contact</h2>
                    <p>
                    Pour toute question concernant cette Politique de Confidentialité ou pour exercer vos droits, veuillez nous contacter à l'adresse suivante : <strong><Link to='javascript:void(0)' className="hover:text-[#f4b814] transition-all" onClick={() => window.location = 'mailto:contact@orazio-consulting.fr'}>contact@orazio-consulting.fr</Link></strong>.
                    </p>
                </section>
            </div>
        </div>
    );
}

export default Confidentialite;