import React from "react";
import Header from "./../components/layout/header.jsx";
import backgroundImage from "./../assets/img/wallpaper_home.webp";
import ContactForm from "./../components/layout/contact.jsx";
import CEO from "./../components/elements/ceo.jsx";

const Contact = () => {
    return (
        <div className="bg-[#FAFAFA]">
            <Header 
                backgroundImage={backgroundImage}
                title="NOUS CONTACTER"
                desc="Nous sommes à votre disposition pour répondre à vos questions"
                buttonON="true"
            />
            <ContactForm />
            <CEO />
        </div>
    );
}

export default Contact;