import React from 'react';
import { motion } from 'framer-motion';
import useInView from './useInView.jsx'; // Assurez-vous de mettre le bon chemin pour useInView
import imageAbout1 from './../../assets/img/imageAbout1.webp';
import imageAbout2 from './../../assets/img/imageAbout2.webp';

const AboutBis = () => {
  // Références et visibilité
  const [textRef, isTextVisible] = useInView({ threshold: 0.1 }); // Détection de visibilité pour le texte
  const [imageRefs, setImageRefs] = React.useState([]); // Pour stocker les références des images
  const [imageVisible, setImageVisible] = React.useState(false); // Pour suivre la visibilité des images

  // Fonction pour définir les références des images
  const setRef = (element) => {
    if (element && !imageRefs.includes(element)) {
      setImageRefs((prev) => [...prev, element]);
    }
  };

  // Observer pour les images
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setImageVisible(true);
          observer.unobserve(entry.target); // Stop observing une fois qu'il est visible
        }
      });
    });

    imageRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      imageRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [imageRefs]);

  // Variantes d'animation
  const imageVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="bg-[#FAFAFA] p-8 min-h-screen flex items-center justify-center">
      <div className="items-center justify-center container mx-auto grid md:grid-cols-2 gap-8">
        {/* Section du texte */}
        <div className="text-grey-700">
          <motion.h2
            ref={textRef} // Référence pour le texte
            className="text-3xl text-[#595959] font-bold mb-4"
            initial="hidden"
            animate={isTextVisible ? "visible" : "hidden"}
            variants={textVariants}
            transition={{ duration: 0.8 }} // Durée de l'animation
          >
            Notre Approche
          </motion.h2>
          <motion.p
            ref={textRef} // Référence pour le texte
            className="text-gray-500 mb-6"
            initial="hidden"
            animate={isTextVisible ? "visible" : "hidden"}
            variants={textVariants}
            transition={{ duration: 0.8 }} // Durée de l'animation
          >
            Chez ORAZIO Consulting, nous mettons un point d'honneur à offrir un accompagnement sur mesure à chaque entreprise. Nous comprenons que chaque client a des besoins spécifiques, c’est pourquoi notre méthodologie repose sur une analyse approfondie et une stratégie personnalisée.
          </motion.p>
          <motion.p
            ref={textRef} // Référence pour le texte
            className="text-gray-500 mb-6"
            initial="hidden"
            animate={isTextVisible ? "visible" : "hidden"}
            variants={textVariants}
            transition={{ duration: 0.8 }} // Durée de l'animation
          >
            Nous commençons par évaluer votre situation actuelle et vos objectifs à court et long terme. Une fois ce diagnostic établi, nous élaborons une stratégie digitale complète qui intègre la création de votre site web, la gestion de vos campagnes publicitaires et le community management. Nous veillons à ce que toutes les actions mises en place soient en parfaite adéquation avec vos objectifs.  
          </motion.p>
          <motion.p
            ref={textRef} // Référence pour le texte
            className="text-gray-500 mb-6"
            initial="hidden"
            animate={isTextVisible ? "visible" : "hidden"}
            variants={textVariants}
            transition={{ duration: 0.8 }} // Durée de l'animation
          >
            Notre engagement ne s’arrête pas à la simple mise en œuvre. Nous suivons et optimisons régulièrement les actions entreprises afin de garantir des résultats mesurables. La transparence est au cœur de notre travail, c'est pourquoi vous recevrez des rapports détaillés pour suivre les progrès de votre stratégie.
          </motion.p>
          <motion.p
            ref={textRef} // Référence pour le texte
            className="text-gray-500 mb-6"
            initial="hidden"
            animate={isTextVisible ? "visible" : "hidden"}
            variants={textVariants}
            transition={{ duration: 0.8 }} // Durée de l'animation
          >
            Avec ORAZIO Consulting, vous bénéficiez d’un accompagnement constant et d’une expertise qui évolue avec vos besoins et les tendances du marché digital.
          </motion.p>
        </div>

        {/* Section des images */}
        <div className="grid grid-cols-2 gap-8">
          {[...Array(2)].map((_, index) => (
            <motion.img
              key={index}
              src={[imageAbout1, imageAbout2][index]}
              alt={`Image ${index + 1}`}
              className="rounded-lg object-cover h-[600px] w-[300px]"
              initial="hidden"
              animate={imageVisible ? "visible" : "hidden"}
              variants={imageVariants}
              transition={{ duration: 0.5, delay: index * 0.2 }} // Délai pour chaque image
              ref={setRef} // Définition de la référence
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutBis;
