// src/components/layout/Header.js

import React, { useEffect, useState } from "react";
import Button from './../elements/button.jsx';

const Header = ({ backgroundImage, title, subtitle, desc, buttonON }) => {
    const [isTextVisible, setIsTextVisible] = useState(false); // État pour le texte

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTextVisible(true); // Afficher le texte après 100 ms
        }, 100);

        return () => clearTimeout(timer); // Nettoyage
    }, []);

    return (
        <header className="h-screen bg-cover bg-center text-gray-700 z-0" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="inset-0 bg-black opacity-30"></div>

            <div className="h-screen flex flex-col justify-center items-center text-center ">
                <h1 className={`text-3xl font-bold md:text-5xl lg:text-6xl transition-all duration-700 ease-in-out ${isTextVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                    {title}
                </h1>
                <h1 className={`text-3xl font-bold md:text-5xl lg:text-6xl transition-all duration-700 ease-in-out ${isTextVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                    {subtitle}
                </h1>
                <p className={`m-8 text-xl md:text-2xl lg:text-3xl transition-all duration-700 ease-in-out delay-100 ${isTextVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                    {desc}
                </p>
                {buttonON && ( // Conditionnel pour afficher le bouton
                    <Button text="DÉCOUVRIR" link="/services" />
                )}
            </div>
        </header>
    );
}

export default Header;
