import React from 'react';
import Header from '../components/layout/header.jsx'
import backgroundImage from "./../assets/img/wallpaper_home.webp";
import Button from '../components/elements/button.jsx'; // Assurez-vous que le bouton est bien importé
import designWeb from "./../assets/img/design_web.webp";
import mobileWeb from "./../assets/img/mobile_web.webp";

const WebService = () => {
  return  (
    <div className='bg-[#FAFAFA]'>
      {/* Header avec l'image de fond */}
      <Header 
        backgroundImage={backgroundImage}
        title="Création de Site Web"
        desc="Nous créons des sites web sur mesure, modernes, intuitifs et optimisés pour la performance. Découvrez pourquoi nous sommes le partenaire idéal pour vos projets en ligne."
        buttonON="true"
      />

      {/* Section présentation du service */}
      <section className="container mx-auto py-16 px-4">
        {/* Explication détaillée */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Un site web qui vous ressemble</h2>
          <p className="text-lg text-gray-600 leading-relaxed max-w-4xl mx-auto">
            Votre site web est bien plus qu'une simple vitrine. Il est le reflet de votre entreprise, de vos valeurs et de votre engagement envers vos clients. 
            Nous mettons en place des solutions digitales sur mesure, que vous soyez une startup, une PME ou une grande entreprise.
            Nos experts en développement web créent des plateformes intuitives, responsives et optimisées pour offrir une expérience utilisateur exceptionnelle, tout en respectant les meilleures pratiques de SEO.
          </p>
        </div>

        {/* Images illustratives avec descriptions */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <div className="relative">
            <img 
              src={designWeb} 
              alt="Exemple de site web moderne"
              className="rounded-lg shadow-lg object-cover w-full"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <p className="text-white text-xl font-semibold">Design moderne et intuitif</p>
            </div>
          </div>
          <div className="relative">
            <img 
              src={mobileWeb}
              alt="Site optimisé pour mobile"
              className="rounded-lg shadow-lg object-cover w-full"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <p className="text-white text-xl font-semibold">Optimisation mobile et responsive</p>
            </div>
          </div>
        </div>

        {/* Points clés du service */}
        <div className="text-center mb-16">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6">Pourquoi nous choisir ?</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-[#F1F1F1] p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-bold mb-4">Design Personnalisé</h4>
              <p className="text-gray-600">Chaque projet est unique. Nous créons des designs adaptés à votre image de marque pour que votre site reflète parfaitement votre identité.</p>
            </div>
            <div className="bg-[#F1F1F1] p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-bold mb-4">Performance Optimale</h4>
              <p className="text-gray-600">Nos sites sont rapides, fiables et optimisés pour offrir une expérience fluide à vos utilisateurs, quel que soit l'appareil utilisé.</p>
            </div>
            <div className="bg-[#F1F1F1] p-6 rounded-lg shadow-sm">
              <h4 className="text-xl font-bold mb-4">Support et Maintenance</h4>
              <p className="text-gray-600">Nous vous accompagnons après la mise en ligne avec des services de maintenance et de support pour garantir que votre site reste à jour et performant.</p>
            </div>
          </div>
        </div>

        {/* Call to Action section */}
        <div className="text-center py-16 bg-[#F1F1F1] text-gray-600 rounded-lg shadow-sm">
          <h3 className="text-3xl text-gray-800 font-semibold mb-6">Vous avez un projet ?</h3>
          <p className="text-lg mb-8 ml-8 mr-8 text-gray-500 ">
            Contactez-nous dès aujourd'hui pour discuter de vos besoins et obtenir une proposition personnalisée. Notre équipe est prête à vous accompagner.
          </p>
          <Button text="NOUS CONTACTER" link="/contact" />
        </div>
      </section>
    </div>
  );
}

export default WebService;